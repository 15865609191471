<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        route-name="printPublication_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="printPublication.id"
                                 @deleteRecord="deletePrintPublication"></app-button-delete>
              <app-button-close route-name="printPublication_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <input type="hidden" v-model="printPublication.id">
                    <app-select
                      v-model="printPublication.printTitle"
                      :options="printTitles"
                      :error="$v.printPublication.printTitle.$error"
                      id="printPublication_title_title"
                      :label="$t('printPublication.title_title')">
                    </app-select>

                    <app-input
                      v-model="printPublication.title"
                      @blur="$v.printPublication.title.$touch()"
                      :error="$v.printPublication.title.$error"
                      id="printPublication_title"
                      :label="$t('printPublication.title')"
                    >
                    </app-input>

                    <app-input
                      v-model="printPublication.slug"
                      @blur="$v.printPublication.slug.$touch()"
                      :error="$v.printPublication.slug.$error"
                      id="printPublication_slug"
                      :label="$t('printPublication.slug')"
                    >
                    </app-input>

                    <app-datepicker
                      type="date"
                      v-model="printPublication.publicationDate"
                      id="printPublication_publicationDate"
                      :label="$t('printPublication.publication_date')">
                    </app-datepicker>

                    <label>{{ $t('printPublication.description') }}</label><br>
                    <app-rich-text-editor
                      v-model="printPublication.description"
                      :other-options="richTextEditorConfig"
                      id="printPublication_description"
                      rows="10"
                    >
                    </app-rich-text-editor>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>{{ $t('printPublication.image') }}</label><br>
                      <app-media-select-button
                        @set-media="setImage"
                      >
                      </app-media-select-button>
                      <app-media-upload-button
                        @set-media="setImage"
                      >
                      </app-media-upload-button>
                    </div>
                    <app-media-editable
                      v-if="image"
                      :media="image"
                      :width="448"
                      :height="0"
                      @remove-media="removeImage"
                    >
                    </app-media-editable>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import { helpers, required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
import PrintPublicationModel from '../../model/PrintPublication'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import Datepicker from '../form/Datepicker'
import RichTextEditor from '../form/RichTextEditor'
import TinyMceConfig from '../../services/tinymce/printPublication/TinyMceConfig'
import MediaSelectButton from '../shared/MediaSelectButton'
import MediaUploadButton from '../shared/MediaUploadButton'
import MediaEditable from '../shared/MediaEditable'

const slugValidation = helpers.regex('nameValidation', /^[a-z\-0-9]+$/)

export default {
  name: 'PrintPublicationNew',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      printPublication: this._.cloneDeep(PrintPublicationModel),
      richTextEditorConfig: TinyMceConfig.getConfig()
    }
  },
  computed: {
    printTitles () {
      return this.$store.getters['printTitle/all']
    },
    image () {
      return this.$store.getters['printPublication/image']
    },
    isImageValid () {
      if (this.$store.getters['printPublication/image'] !== null) {
        return true
      } else {
        return false
      }
    }
  },
  validations: {
    printPublication: {
      title: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      slug: {
        slugValidation,
        minLength: minLength(2),
        maxLength: maxLength(255)
      },
      printTitle: {
        required,
        minValue: minValue(1)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appDatepicker: Datepicker,
    appRichTextEditor: RichTextEditor,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable
  },
  methods: {
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid || !this.isImageValid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.printPublication.image = null
        if (this.$store.getters['printPublication/image']) {
          this.printPublication.image = this.$store.getters['printPublication/image'].id
        }
        this.$store.dispatch('printPublication/create', this.printPublication)
          .then(() => {
            if (this.$store.getters['printPublication/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.printPublication = this.$store.getters['printPublication/detail']
              this.$router.push('/printPublication/' + this.printPublication.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['printPublication/error'])
            }
          })
          .catch(error => console.log(error))
      }
    },
    deletePrintPublication () {
      this.$store.dispatch('printPublication/deleteRecord', this.printPublication)
        .then(() => {
          if (this.$store.getters['printPublication/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/printPublication')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['printPublication/error'])
          }
        })
        .catch(error => console.log(error))
    },
    getPrintTitles () {
      this.$store.dispatch('printTitle/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('printPublication/storeImage', media)
      })
    },
    removeImage () {
      this.$store.commit('printPublication/storeImage', null)
    }
  },
  created () {
    this.getPrintTitles()
    this.$store.commit('printPublication/storeImage', null)
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
